import { Fragment, useEffect, useState } from "react";
import { Alert, Typography, Button, Divider, IconButton, Badge, Snackbar, Backdrop } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward, Close, NotificationImportant, Notifications } from "@mui/icons-material";

export default function AlertComunicazioneUrgente(props) {

    const navigate = useNavigate();
    const [numeroComunicazioniDaLeggere, setNumeroComunicazioniDaLeggere] = useState(0);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (props.numeroComunicazioniDaLeggere !== undefined) {
            setNumeroComunicazioniDaLeggere(props.numeroComunicazioniDaLeggere);
        }
    }, [props.numeroComunicazioniDaLeggere]);

    function goToElencoComunicazioni() {
        navigate('/comunicazioni/elenco');
    }

    function handleCloseAlert() {
        setShowAlert(false); // Close the Snackbar and Backdrop
    }

    return (
        <Fragment>

            <IconButton onClick={() => setShowAlert(prev => !prev)}
                sx={{
                    p: 0.5,
                    ml: 2,
                    borderRadius: '10%',
                    border: '1px solid white',
                    backgroundColor: 'transparent',
                    '& .MuiSvgIcon-root': {
                        color: 'white',
                    },
                }}
            >
                <Badge color="error" badgeContent={numeroComunicazioniDaLeggere}
                    max={9} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                >
                    {numeroComunicazioniDaLeggere > 0 ? <NotificationImportant /> : <Notifications />}
                </Badge>
            </IconButton>

            {/* Backdrop when Snackbar is open */}
            <Backdrop
                open={showAlert}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it appears above other elements
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
                }}
            />

            <Snackbar
                open={showAlert}
                autoHideDuration={10000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    sx={{
                        p:2,
                        width: '100%', // Full width of the screen
                        mx: 0,         // Remove margins to take full width
                        mb: 2,         // Optional: margin at the bottom
                        boxSizing: 'border-box', // Ensure padding is considered in the width
                    }}
                    severity="error"
                    action={
                        <>
                            <Button
                            sx={{ml:3}}
                                endIcon={<ArrowForward />}
                                color="error"
                                variant="outlined"
                                onClick={goToElencoComunicazioni}
                            >
                                Apri elenco comunicazioni
                            </Button>
                            <Button
                                endIcon={<Close />}
                                color="inherit"
                                variant="outlined"
                                onClick={handleCloseAlert}
                                sx={{ ml: 2,mr:1 }}
                            >
                                Chiudi
                            </Button>
                        </>
                    }
                >
                    {numeroComunicazioniDaLeggere === 1 ? (
                        <Typography>
                            C'è <b>1 comunicazione</b> non letta
                        </Typography>
                    ) : (
                        <Typography>
                            Ci sono <b>{numeroComunicazioniDaLeggere} comunicazioni</b> non lette
                        </Typography>
                    )}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}
