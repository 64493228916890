import './App.css';
import { Fragment, useState, useEffect } from 'react';
import Login from './components/auth/login';
import RicercaStudente from './components/ricerca/ricercaStudente';
import ErrorPage from './components/error';

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MenuComponent from './components/menu';
import AlertComunicazioneUrgente from './components/shared/alertComunicazioneUrgente';
import { Box, Button, Dialog, DialogTitle, DialogContent, Typography, Fab, Tooltip, IconButton, Divider } from '@mui/material';
import { Logout, Person, Close, QuestionMark } from '@mui/icons-material';
import AggiuntaVoto from './components/eventi/aggiuntaVoto';

import ModuloComunicazioni from './components/comunicazioni/moduloComunicazioni';
import Protected from './components/auth/protected';
import CryptoJS from 'crypto-js';
import GestioneCompiti from './components/eventi/compiti/gestioneCompiti';
import GestioneVerifiche from './components/eventi/verifiche/gestioneVerifiche';
import GestioneArgomenti from './components/eventi/argomenti/gestioneArgomenti';
import RegistroDiSintesi from './components/registri/registroDiSintesi';
import ValidazionePresenze from './components/validazione/validazionePresenze';
import GestioneNoteDisciplinari from './components/eventi/noteDisciplinari/gestioneNoteDisciplinari';
import RiepilogoAttivitaFormativa from './components/riepilogo-attivita-formativa/riepilogoAttivitaFormativa';
import { UtilityAPI } from './api/utilityApi';
import { LogoutPage } from './components/shared/logoutPage';
import TestVirtualized from './components/registri/testVirtualized';
import TracciatiBes from './components/ricerca/tracciatiBes';
import RecuperoPin from './components/auth/recuperoPin';
import { ComunicazioniAPI } from './api/comunicazioniApi';
import RiepilogoAttivitaFormativaAlunno from './components/riepilogo-attivita-formativa/riepilogoAttivitaFormativaAlunno';
import ExportExAnte from './components/exportExAnte';
import RegistroDidatticaSintesiMateria from './components/scrutini/registroDidatticaSintesiMateria/registroDidatticaSintesiMateria';
import ElencoAlunniRiepilogoDidattico from './components/scrutini/elencoAlunniRiepilogoDidattico/elencoAlunniRiepilogoDidattico';
import GestionePeriodi from './components/gestionePeriodi/gestionePeriodi';
import Home from './components/auth/home';
import ElencoColloqui from './components/colloqui/annotazioni-colloqui/elencoColloqui';
import GestioneCalendarioColloqui from './components/colloqui/gestione-calendario-colloqui/gestioneCalendarioColloqui';
import DettaglioColloquio from './components/colloqui/annotazioni-colloqui/dettaglioColloquio';
import { EasterEgg } from './components/shared/easterEgg';
import SintesiMonitoraggioAppello from './components/monitoraggio/sintesiMonitoraggioAppello';
import SintesiMonitoraggioFirme from './components/monitoraggio/sintesiMonitoraggioFirme';
import { axiosCalls } from './api/configs/axiosCalls';
import LoginDebug from './components/debug/loginDebug';
import InfoUtente from './components/debug/infoUtente';
import RiepilogoArgomentiSvolti from './components/eventi/riepilogoArgomentiSvolti';
import GestioneCreditiFormativi from './components/gestione-crediti-formativi/gestioneCreditiFormativi';
import RegistroSintesiTirocinio from './components/tirocini/registro-sintesi-tirocinio/registroSintesiTirocinio';
import PresenzeTutorTirocinio from './components/tirocini/presenze-tutor-tirocinio/presenzeTutorTirocinio';
import SintesiValidazionePresenze from './components/tirocini/validazione-presenze/sintesiValidazionePresenze';
import ValidazionePresenzeTutorTirocinio from './components/tirocini/validazione-presenze-tutor/validazionePresenzeTutorTirocinio';
import GestioneAziende from './components/tirocini/gestione-aziende/gestioneAziende';
import OrarioFormativo from './components/tirocini/gestione-progetti-formativi/orarioFormativo';
import GestioneConvenzioni from './components/tirocini/gestione-convenzioni/gestioneConvenzioni';
import GestioneProgettiFormativi from './components/tirocini/gestione-progetti-formativi/gestioneProgettiFormativi';
import GestioneTirocinio from './components/tirocini/gestione-tirocinio/gestioneTirocinio';
import RegistroTirocinioAlunno from './components/tirocini/new-registro-tirocinio/registroTirocinioAlunno';
import FormAssistenza from './components/assistenza/formAssistenza';
import ConfermaTicket from './components/assistenza/confermaTicket';
import GestioneDatiRicorrentiSedi from './components/tirocini/gestione-dati-ricorrenti/gestioneDatiRicorrentiSedi';
import ElencoAccertamentoPrerequisiti from './components/accertamento-prerequisiti/elenco/elencoAccertamentoPrerequisiti';
import GestioneDatiRicorrentiCorsi from './components/tirocini/gestione-dati-ricorrenti/gestioneDatiRicorrentiCorsi';
import GestioneAziendaSimulata from './components/tirocini/gestione-azienda-simulata/gestioneAziendaSimulata';
import GiorniSospensione from './components/tirocini/giorni-sospensione/giorniSospensione';

export default function App() {
  // const url = document.URL.includes('/login');
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [objUser, setObjUser] = useState(null);
  const [annoFormativoSelezionato, setAnnoFormativoSelezionato] = useState("20232024");
  const [annoFormativoCorrente, setAnnoFormativoCorrente] = useState(null);
  const [numeroComunicazioniDaLeggere, setNumeroComunicazioniDaLeggere] = useState(0);
  const [inElencoComunicazioni, setInElencoComunicazioni] = useState(false);
  const [listAnniFormativi, setListAnniFormativi] = useState([])
  const location = useLocation();

  const [isDialogLogInOutOpen, setIsDialogLogInOutOpen] = useState(false);
  const [isDialogReteOpen, setIsDialogReteOpen] = useState(false);
  const [isDialogInfoUtenteOpen, setIsDialogInfoUtenteOpen] = useState(false);

  const [oreNonFirmate, setOreNonFirmate] = useState([]);

  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (refresh) {
      window.location.reload();
    }
  }, [refresh]);

  useEffect(() => {
    axiosCalls.length = 0
  }, [location])

  useEffect(() => {
    getAnniFormativi();
    if (localStorage.getItem("log")) {
      let isLoggedTmp = localStorage.getItem("log");
      let decryptIsLogged = CryptoJS.AES.decrypt(isLoggedTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
      setIsLoggedIn(Boolean(decryptIsLogged))
      getUserLoggedInfo();
    }


    getOreNonFirmate();
    getNumeroComunicazioniDaLeggere();

    let checkComunicazioniAndOreNonFirmate = setInterval(
      async () => {
        if (isLoggedIn) {
          getNumeroComunicazioniDaLeggere();
          getOreNonFirmate();
        }
      }
      , 30000); //300000
    return () => {
      clearInterval(checkComunicazioniAndOreNonFirmate);
    };

  }, []);

  useEffect(() => {
    if (location.pathname === '/comunicazioni/elenco') setInElencoComunicazioni(true)
    else {
      getNumeroComunicazioniDaLeggere();
      setInElencoComunicazioni(false)
    }
    if (sessionStorage.getItem("matDoc"))
      sessionStorage.removeItem("matDoc")
  }, [location]);

  function logout() {
    if (process.env.REACT_APP_IS_DEBUG == "true") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace(process.env.REACT_APP_HOME_URL + "login");

    } else {
      let idTmp = localStorage.getItem("id");
      let decryptedId = CryptoJS.AES.decrypt(idTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
      let dataObj = {
        idUtente: decryptedId
      }
      let gtmUrl = process.env.REACT_APP_GTM_URL + "?page=logout"
      sessionStorage.clear();
      window.location.replace(gtmUrl);
    }
  }

  function isLoggin(data) {

    let objUserTmp = {
      id: data.id,
      nome: data.nome,
      ruolo: data.titoloVisualizzato,
      sede: data.sede,
      permessi: data.permessi,
      objRuoli: data.objRuoli
    }
    setObjUser(objUserTmp);
    setIsLoggedIn(true);

    getAnniFormativi();
    getNumeroComunicazioniDaLeggere()
  }

  function getUserLoggedInfo() {
    let nomeTmp = localStorage.getItem("usr");
    let decryptedNome = CryptoJS.AES.decrypt(nomeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

    let idTmp = localStorage.getItem("id");
    let decryptedId = CryptoJS.AES.decrypt(idTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

    let ruoloTmp = localStorage.getItem("ruolo");
    let decryptedRuolo = CryptoJS.AES.decrypt(ruoloTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

    let sedeTmp = localStorage.getItem("sede");
    let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
    let sedeParsed = JSON.parse(decryptedSede);

    let permessiTmp = localStorage.getItem("permessi");
    let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
    let permessiParsed = JSON.parse(decryptedPermessi);

    let ruoliObjTmp = "";
    let ruoliObjParsed = {};
    if (localStorage.getItem("ruoliObj")) {
      ruoliObjTmp = localStorage.getItem("ruoliObj");
      let decryptedRuoliObjTmp = CryptoJS.AES.decrypt(ruoliObjTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
      ruoliObjParsed = JSON.parse(decryptedRuoliObjTmp);
    }
    let objUserTmp = {
      id: decryptedId,
      nome: decryptedNome,
      ruolo: decryptedRuolo,
      sede: sedeParsed,
      permessi: permessiParsed,
      objRuoli: ruoliObjParsed
    }
    setObjUser(objUserTmp);
  }

  async function getPermessiByAnnoFormativo(annoFormativo) {
    setIsLoading(true);
    let obj = {
      annoFormativo: annoFormativo
    };
    UtilityAPI.getPermessiByAnnoFormativo(obj)
      .then((result) => {
        if (result.idDocente != null) {
          let idDocenteString = result.idDocente.toString(); //Crypto non vuole numeri, ma solo stringhe
          let idDocente = CryptoJS.AES.encrypt(idDocenteString, process.env.REACT_APP_PRIVATE_KEY).toString();
          localStorage.setItem("doc", idDocente);
        }
        let idString = result.id.toString(); //Crypto non vuole numeri, ma solo stringhe
        let id = CryptoJS.AES.encrypt(idString, process.env.REACT_APP_PRIVATE_KEY).toString();
        let nomeUtente = CryptoJS.AES.encrypt(result.nome, process.env.REACT_APP_PRIVATE_KEY).toString();

        let permessiString = JSON.stringify(result.permessi);
        let permessi = CryptoJS.AES.encrypt(permessiString, process.env.REACT_APP_PRIVATE_KEY).toString();

        let ruolo = CryptoJS.AES.encrypt(result.titoloVisualizzato, process.env.REACT_APP_PRIVATE_KEY).toString();

        let sedeString = JSON.stringify(result.sede);
        let sede = CryptoJS.AES.encrypt(sedeString, process.env.REACT_APP_PRIVATE_KEY).toString();

        let ruoliObjString = JSON.stringify(result.objRuoli);
        let objRuoli = CryptoJS.AES.encrypt(ruoliObjString, process.env.REACT_APP_PRIVATE_KEY).toString();

        localStorage.setItem("id", id);
        localStorage.setItem("usr", nomeUtente);
        localStorage.setItem("permessi", permessi);
        localStorage.setItem("ruolo", ruolo);
        localStorage.setItem("sede", sede);
        localStorage.setItem("ruoliObj", objRuoli)

        localStorage.setItem("annoFormativo", annoFormativo);
        sessionStorage.setItem('checkAnnoFormativo', true)
        setIsLoading(false);
        setRefresh(true);
        // navigate(0)
      })
      .catch((error) => { })
  }

  async function getNumeroComunicazioniDaLeggere() {
    let annoFormativoTmp = process.env.REACT_APP_ANNO_SCOLASTICO;
    if (sessionStorage.getItem("annoFormativo")) {
      annoFormativoTmp = sessionStorage.getItem("annoFormativo")
      setAnnoFormativoSelezionato(sessionStorage.getItem("annoFormativo"));
    } else {
      annoFormativoTmp = process.env.REACT_APP_ANNO_SCOLASTICO;
      setAnnoFormativoSelezionato(process.env.REACT_APP_ANNO_SCOLASTICO);
    }


    const dataObj = {
      annoFormativo: annoFormativoTmp
    }

    ComunicazioniAPI.numeroComunicazioniDaLeggere(dataObj)
      .then((result) => {
        setNumeroComunicazioniDaLeggere(result?.numeroNuoveComunicazioni ?? 0)
      })
      .catch((error) => {
      })

  }

  async function getAnniFormativi() {
    UtilityAPI.getAnniFormativi()
      .then((result) => {
        let listAnniFormativiTmp = result?.anniFormativi;
        listAnniFormativiTmp.sort((a, b) => b.id - a.id);

        setListAnniFormativi([...listAnniFormativiTmp]);
        //TODO: funzione di controllo annoFormativo
        let annoFormativoCorrenteObj = listAnniFormativiTmp.filter(i => i.isAnnoFormativoCorrente === true);
        let annoFormativoCorrente = annoFormativoCorrenteObj[0].id;
        if (localStorage.getItem("annoFormativo")) {
          let annoFormativoTmp = localStorage.getItem("annoFormativo");
          if (sessionStorage.getItem('checkAnnoFormativo') !== 'true' && annoFormativoCorrente !== parseInt(annoFormativoTmp)) {
            getPermessiByAnnoFormativo(annoFormativoCorrente);
          }
          else sessionStorage.setItem('checkAnnoFormativo', true);
        }
      })
      .catch((error) => { })
  }


  async function getOreNonFirmate() {
    UtilityAPI.getOreNonFirmate()
      .then((result) => {
        setOreNonFirmate([...result.ore]);
      })
      .catch((error) => { })
  }

  function openDialogRete() {
    setIsDialogReteOpen(prev => !prev)
  }

  function openDialogLogInOut() {
    setIsDialogLogInOutOpen(prev => !prev)
  }

  function openDialogInfoUtente() {
    setIsDialogInfoUtenteOpen(prev => !prev)

  }

  function goToAssistenza() {
    navigate('/assistenza', { state: { from: window.location.pathname } });
  }

  return (
    <Fragment>
      {isLoggedIn && (
        <Fragment>
          <MenuComponent  numeroComunicazioniDaLeggere={numeroComunicazioniDaLeggere} inElencoComunicazioni={inElencoComunicazioni}
           oreNonFirmate={oreNonFirmate} listAnniFormativi={listAnniFormativi} permessi={objUser?.permessi} user={objUser} logout={logout} />
          <Box sx={{ height: '8vh' }}></Box>
        
        </Fragment>
      )}

      {(process.env.REACT_APP_IS_DEBUG == "true" && location.pathname !== '/login') && (
        <Box sx={{ position: 'fixed', bottom: 15, left: '50%', transform: 'translateX(-50%)', zIndex: '3000!important' }}>
          <Tooltip title="Cambia utente" arrow>
            <Fab onClick={openDialogLogInOut} sx={{ mr: 1 }} color='error'>
              <Logout />
            </Fab>
          </Tooltip>
          {/* <Tooltip title="Registro rete" arrow>
      <Fab onClick={openDialogRete} sx={{ mr: 1 }} color='warning'>
        <NetworkWifi />
      </Fab>
    </Tooltip> */}
          <Tooltip title="Info utente" arrow>
            <Fab onClick={openDialogInfoUtente} color='primary'>
              <Person />
            </Fab>
          </Tooltip>
        </Box>
      )}
      {(location.pathname === '/login') && (
        <Box sx={{ position: 'fixed', bottom: 15, right: 15, transform: 'translateX(-50%)', zIndex: '3000!important' }}>
          <Tooltip title="Assistenza" arrow>
            <Fab onClick={goToAssistenza} color='warning'>
              <QuestionMark />
            </Fab>
          </Tooltip>
        </Box>)}

      <Dialog fullWidth maxWidth={"xs"} open={isDialogLogInOutOpen} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
        <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
          <Typography variant='h6' >
            <b>Cambio utente</b>
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <LoginDebug openDialogLogInOut={openDialogLogInOut} />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth maxWidth={"xs"} onClose={openDialogInfoUtente} open={isDialogInfoUtenteOpen} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">Info utente</Typography>
            <IconButton onClick={openDialogInfoUtente}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <InfoUtente closeDialog={openDialogInfoUtente} />
        </DialogContent>
      </Dialog>

      <Routes>
        <Route path="/login" element={<Login isLoggedIn={isLoggedIn} isLoggin={isLoggin} checkComunicazioni={getNumeroComunicazioniDaLeggere} />} />
        <Route path='/recupera-pin' element={<RecuperoPin />} />
        <Route path="/assistenza" element={<FormAssistenza location={location.pathname} />} />
        <Route path="/conferma-ticket" element={<ConfermaTicket />} />
        <Route path="/" element={
          <Protected>
            {/* <NewHome logout={logout} permessiUtente={objUser?.permessi} /> */}
            <Home logout={logout} permessiUtente={objUser?.permessi} />
          </Protected>
        } />
        <Route path="/home" element={
          <Protected>
            {/* <NewHome logout={logout} permessiUtente={objUser?.permessi} /> */}
            <Home logout={logout} permessiUtente={objUser?.permessi} isLoading={isLoading} />
          </Protected>
        } />

        {/* showExportExAnte */}
        <Route path="/export-ex-ante" element={
          <Protected permessoNecessario={"showExportExAnte"}>
            <ExportExAnte />
          </Protected>
        } />
         <Route path="/comunicazioni/elenco" element={
          <Protected>
            <ModuloComunicazioni tabIndex={0} logout={logout} />
          </Protected>
        } />
        <Route path="/comunicazioni/aggiungi" element={
          <Protected>
            <ModuloComunicazioni tabIndex={1} logout={logout} />
          </Protected>
        } />
        <Route path="/riepilogo-attivita-formativa" element={
          <Protected permessoNecessario={"showRegistroClasse"}>
            <RiepilogoAttivitaFormativa tipoRegistro={"classe"} logout={logout} />
          </Protected>
        } />
        <Route path="/riepilogo-attivita-formativa-alunno" element={
          <Protected permessoNecessario={"showAttivitaFormativaAlunno"}>
            <RiepilogoAttivitaFormativaAlunno tipoRegistro={"classe"} logout={logout} />
          </Protected>
        } />
        <Route path="/registro/registro-del-docente" element={
          <Protected permessoNecessario={"showRegistroDocente"}>
            <RegistroDiSintesi ruoliUtente={objUser?.objRuoli} tipoRegistro={"docente"} logout={logout} />
          </Protected>
        } />
        <Route path="/registro/registro-di-classe" element={
          <Protected permessoNecessario={"showRegistroClasse"}>
            <RegistroDiSintesi ruoliUtente={objUser?.objRuoli} tipoRegistro={"classe"} logout={logout} />
          </Protected>
        } />
        <Route path="/registro/consulta-registro-di-classe" element={
          <Protected permessoNecessario={"showConsultaRegistroClasse"}>
            <RegistroDiSintesi ruoliUtente={objUser?.objRuoli} isDocente={false} tipoRegistro={"classe"} logout={logout} />
          </Protected>
        } />
        <Route path="/registro/consulta-registro-docente" element={
          <Protected permessoNecessario={"showConsultaRegistroDocente"}>
            <RegistroDiSintesi ruoliUtente={objUser?.objRuoli} isDocente={false} tipoRegistro={"docente"} logout={logout} />
          </Protected>
        } />
        <Route path="/ricerca-alunni" element={
          <Protected>
            <RicercaStudente logout={logout} />
          </Protected>
        } />
        <Route path="/tracciati-bes" element={
          <Protected>
            <TracciatiBes logout={logout} />
          </Protected>
        } />
        <Route path="/validazione-presenze" element={
          <Protected>
            <ValidazionePresenze logout={logout} />
          </Protected>
        } />
        <Route path="/azioni-corso/gestione-programmi" element={
          <Protected>
            <GestioneArgomenti listAlunni={null} logout={logout} />
          </Protected>
        } />
        <Route path="/azioni-corso/nota-disciplinare" element={
          <Protected>
            <GestioneNoteDisciplinari listAlunni={null} logout={logout} />
          </Protected>
        } />
        <Route path="/azioni-corso/gestione-verifiche" element={
          <Protected>
            <GestioneVerifiche listAlunni={null} logout={logout} />
          </Protected>
        } />
        <Route path="/azioni-corso/gestione-compiti" element={
          <Protected>
            <GestioneCompiti listAlunni={null} logout={logout} />
          </Protected>
        } />
        <Route path="/azioni-corso/voto-valutazione" element={
          <Protected>
            <AggiuntaVoto listAlunni={null} logout={logout} />
          </Protected>
        } />

        <Route path="/registro-didattico-sintesi-materia" element={
          <Protected>
            <RegistroDidatticaSintesiMateria />
          </Protected>
        } />
        <Route path='/elenco-alunni-riepilogo-didattico' element={
          <Protected>
            <ElencoAlunniRiepilogoDidattico />
          </Protected>
        } />

        <Route path="/gestione-periodi" element={
          <Protected>
            <GestionePeriodi logout={logout} />
          </Protected>
        } />
        <Route path="/tirocini/gestione-aziende" element={
          <Protected>
            <GestioneAziende />
          </Protected>
        } />
        <Route path="/tirocini/gestione-convenzioni" element={
          <Protected>
            <GestioneConvenzioni />
          </Protected>
        } />
        <Route path="/tirocini/gestione-progetti-formativi" element={
          <Protected>
            <GestioneProgettiFormativi />
          </Protected>
        } />
        <Route path="/tirocini/gestione-azienda-simulata" element={
          <Protected>
            <GestioneAziendaSimulata />
          </Protected>
        } />
        <Route path="/tirocini/gestione-tirocini" element={
          <Protected>
            <GestioneTirocinio />
          </Protected>
        } />
        <Route path="/tirocini/orario-formativo" element={
          <Protected>
            <OrarioFormativo />
          </Protected>
        } />
        <Route path="/tirocini/registro-sintesi-tirocini" element={
          <Protected>
            <RegistroTirocinioAlunno />
            {/* <RegistroSintesiTirocinio /> */}
          </Protected>
        } />
        <Route path="/tirocini/presenze-tutor-tirocinio" element={
          <Protected>
            <PresenzeTutorTirocinio />
          </Protected>
        } />
        <Route path="/tirocini/validazione-presenze-tutor-tirocinio" element={
          <Protected>
            <ValidazionePresenzeTutorTirocinio />
          </Protected>
        } />
        <Route path="/tirocini/validazione-presenze-tirocini" element={
          <Protected>
            <SintesiValidazionePresenze />
          </Protected>
        } />
         <Route path="/tirocini/gestione-dati-ricorrenti-sedi" element={
          <Protected>
            <GestioneDatiRicorrentiSedi />
          </Protected>
        } />
          <Route path="/tirocini/gestione-dati-ricorrenti-corsi" element={
          <Protected>
            <GestioneDatiRicorrentiCorsi />
          </Protected>
        } />
          <Route path="/tirocini/giorni-sospensione" element={
          <Protected>
            <GiorniSospensione />
          </Protected>
        } />
        <Route path="/gestione-crediti-formativi" element={
          <Protected>
            <GestioneCreditiFormativi />
          </Protected>
        } />

        <Route path="/colloqui/elenco" element={
          <Protected>
            <ElencoColloqui />
          </Protected>
        } />

        <Route path="/colloqui/dettaglio" element={
          <Protected>
            <DettaglioColloquio />
          </Protected>
        } />

        <Route path="/colloqui/gestione-calendario" element={
          <Protected>
            <GestioneCalendarioColloqui />
          </Protected>
        } />

        <Route path="/monitoraggio/monitoraggio-appello" element={
          <Protected>
            <SintesiMonitoraggioAppello />
          </Protected>
        } />

        <Route path="/monitoraggio/monitoraggio-firme" element={
          <Protected>
            <SintesiMonitoraggioFirme />
          </Protected>
        } />
        <Route path="/riepilogo-argomenti-svolti" element={
          <Protected permessoNecessario={"showRiepilogoArgomentiSvolti"}>
            <RiepilogoArgomentiSvolti />
          </Protected>
        } />

        <Route path="/accertamento-prerequisiti" element={
          <Protected permessoNecessario={"showElencoAccertamentoPrerequisiti"}>
            <ElencoAccertamentoPrerequisiti />
          </Protected>
        } />

        <Route path="/logout" element={
          <LogoutPage />
        } />

        <Route path='/virtualized' element={
          <TestVirtualized tipoRegistro={"classe"} logout={logout} />
        } />

        <Route path='/easter-egg' element={
          <EasterEgg />
        } />

        <Route path="*" element={<ErrorPage />} />

      </Routes >
    </Fragment >
  );
}
